<template>
    
    <div class="navbar-item flex-row ms-mdd-auto" >
    
        <div class="dropdown nav-item notification-dropdown btn-group" style="z-index:1002">
            <a href="javascript:;" id="ddlnotify" data-bs-toggle="dropdown" aria-expanded="true" style="max-height:40px;min-width:40px;color:rgb(255 255 255);" class="btn btn-primary dropdown-toggle btn-ison-only nav-link ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg>

                <span class="badge badge-warning"  :style="total == 0 ? '' : 'background:#ff0000'"></span>
        </a>
        <!-- {{missedCount}} -->
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddlnotify" data-bs-popper="none"  >
            <li role="presentation">
                <a href="javascript:;" @click="navigateTo('/company/missed-calls')" class="dropdown-item">
                    <!-- <div class="media" :class="missedCount == 0 ? 'server-log' : 'file-upload' ">  -->
                    <div class="media" :class="missedCount.unassigned + missedCount.incomplete == 0 ? 'server-log' : 'file-upload' "> 
                        <!-- server-log - Green ; nothing - yellow; -->
                        <div class="media-aside align-self-start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-missed" data-v-5522efca=""><line x1="23" y1="1" x2="17" y2="7"></line><line x1="17" y1="1" x2="23" y2="7"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                        </div>
                        <div class="media-body">
                            <div class="data-info">
                                <h6 class="">Missed Calls</h6>
                                <p class="">{{missedCount.unassigned}} Unassigned / {{missedCount.incomplete}} Incomplete</p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li role="presentation"><hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider"></li>
            <li role="presentation">
                <a href="javascript:;" @click="navigateTo('/company/voicemail')" class="dropdown-item">
                    <div class="media" :class="voicemailCount.unassigned + voicemailCount.incomplete == 0 ? 'server-log' : 'file-upload' "> 
                        <!-- server-log - Green ; nothing - yellow; -->
                        <div class="media-aside align-self-start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail" data-v-5522efca=""><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        </div>
                        <div class="media-body">
                            <div class="data-info">
                                <h6 class="">Voicemail</h6>
                                <p class="">{{voicemailCount.unassigned}} Unassigned / {{voicemailCount.incomplete}} Incomplete</p>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <!-- Agent {{currentAgent}} -->
            <!-- <li role="presentation"><hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider"></li>
            <li role="presentation">
                <a href="javascript:;" class="dropdown-item">
                    <div class="media" :class="callbackCount == 0 ? 'server-log' : 'file-upload'">
                        <div class="media-aside align-self-start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left-circle" data-v-5522efca=""><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>
                        </div>
                        <div class="media-body">
                            <div class="data-info">
                                <h6>Callback Offers</h6>
                                <p>{{callbackCount}} new / unread</p>
                            </div>
                            <!- - <div class="icon-status"> -- >
                                <! -- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"> -- >
                                    <!- - <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line> - ->
                                <!- - </svg>  -- >
                            <! - </div> - ->
                        </div>
                    </div>
                </a>
            </li>
            <li role="presentation">
                <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider">
            </li>
            <li role="presentation">
                <a href="javascript:;" class="dropdown-item">
                    <div class="media" :class="faxCount==0 ? 'server-log': 'file-upload'"> 
                        <div class="media-aside align-self-start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-printer" data-v-5522efca=""><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>
                        </div>
                        <div class="media-body">
                            <div class="data-info">
                                <h6>Received Faxes</h6>
                                <p>{{faxCount}} new / unread</p>
                            </div>
                            
                        </div>
                    </div>
                </a>
            </li> -->
        </ul>
    </div>
    
</div>
</template>

<script setup>
    const { $get, $toNationalFormat } = useNuxtApp();
    const voicemailCount = useVMCount();
    const callbackCount = useCBCount();
    const faxCount = useFaxCount();
    const missedCount = useMissedCount();
    
    const total = computed( ()=> { 
        let result = voicemailCount.value.incomplete + voicemailCount.value.unassigned +  missedCount.value.incomplete + missedCount.value.unassigned;
        // alert (JSON.stringify(result));
        return result;
    });
    const currentAgent = useCurrentAgentId();
    const user = useUser();

    const getVoicemails = async () => {
        return (await $get('/api/protected/voicemail?countOnly=true')).data;
    }
    const getMissedCalls = async () => {
        return (await $get('/api/protected/missed-calls?countOnly=true')).data;
    }

    voicemailCount.value = await getVoicemails();
    missedCount.value = await getMissedCalls();
    

    const {voicemailMessages, missedCallsMessages} = useWebSocket();
    const {$swal} = useNuxtApp();

    watch (() => missedCallsMessages.value, async (newValue) => {
        missedCount.value = await getMissedCalls();
        if (newValue.event == "new-missed-call"){
            
            $swal.fire({
                position: 'top-end',
                toast: true,
                title: `You have a Missed Call from ${newValue.data.caller_id_number}`,
                // text: 'Click here to listen',
                icon: 'warning',
                color: "#716add",
                confirmButtonText: 'Open Missed Calls',
                showCancelButton: true,
                cancelButtonText: 'Close'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    navigateTo('/company/missed-calls');
                }
            });
        }

        if (newValue.event == 'job_reassigned') {
            
            if (newValue.data.was_assigned_to == currentAgent.value && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`) {
                // alert(newVal.assigned_by);
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    text: `${newValue.data.assigned_by} has reassigned a task to process a missed call from ${$toNationalFormat(newValue.data.caller_id_number)} from you to another agent.`,
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    background: "#fcfff2",
                    confirmButtonText: 'Open Missed Calls',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/missed-calls');
                    }
                });
            }
            if (newValue.data.assigned_to == currentAgent.value && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`){
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    text: `${newValue.data.assigned_by} has reassigned a task to process a missed call message from ${$toNationalFormat(newValue.data.caller_id_number)} to you.`,
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    background: "#f1f9ff",
                    confirmButtonText: 'Open Missed Calls',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/missed-calls');
                    }
                });
            }
        }
        if (newValue.data.assigned_to == currentAgent.value && newValue.event == 'job_assigned' && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`){
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    text: `${newValue.data.assigned_by} has assigned a new task to process a a missed call from ${$toNationalFormat(newValue.data.caller_id_number)}.`,
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    background: "#fcf1f1",
                    
                    confirmButtonText: 'Open Missed Calls',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/missed-calls');
                    }
                });
            }
            if (newValue.data.was_assigned_to == currentAgent.value && newValue.event == 'job_unassigned' && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`){
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    text: `${newValue.data.assigned_by} has unassigned a task to process a a missed call from ${$toNationalFormat(newValue.data.caller_id_number)}.`,
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    background: "#f3fcf1",
                    
                    confirmButtonText: 'Open Voicemails',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/voicemail');
                    }
                });
            }
    }, {deep:true});
        

    watch (() => voicemailMessages.value, async (newValue) => {
        // if (newValue.app == 'voicemail'){
            // alert('You have a new voicemail');
            
            voicemailCount.value = await getVoicemails();
            // On new voicemail, show a toast notification to everyone
        if (newValue.app == 'voicemail' && newValue.type=="voicemail") {
            if (newValue.event == 'new-voicemail' ){
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    title: 'You have a new voicemail',
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    
                    confirmButtonText: 'Open Voicemails',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/voicemail');
                    }
                });
            }
        }
        if (newValue.app == 'voicemail' && newValue.type=="voicemail-state") {
            if (newValue.event == 'job_reassigned' && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`) {
                // alert("You have a new voicemail");
                if (newValue.data.was_assigned_to == currentAgent.value){
                    $swal.fire({
                        position: 'top-end',
                        toast: true,
                        text: `${newValue.data.assigned_by} has reassigned a task to process a voicemail message from ${$toNationalFormat(newValue.data.caller_id_number)} from you to another agent.`,
                        // text: 'Click here to listen',
                        icon: 'warning',
                        color: "#716add",
                        background: "#fcfff2",
                        
                        confirmButtonText: 'Open Voicemails',
                        showCancelButton: true,
                        cancelButtonText: 'Close'
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            navigateTo('/company/voicemail');
                        }
                    });
                }
                if (newValue.data.assigned_to == currentAgent.value && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`){
                    $swal.fire({
                        position: 'top-end',
                        toast: true,
                        text: `${newValue.data.assigned_by} has reassigned a task to process a voicemail message from ${$toNationalFormat(newValue.data.caller_id_number)} to you.`,
                        // text: 'Click here to listen',
                        icon: 'warning',
                        color: "#716add",
                        background: "#f1f9ff",
                        
                        confirmButtonText: 'Open Voicemails',
                        showCancelButton: true,
                        cancelButtonText: 'Close'
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            navigateTo('/company/voicemail');
                        }
                    });
                }
            }
            if (newValue.data.assigned_to == currentAgent.value && newValue.event == 'job_assigned' && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`) {
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    text: `${newValue.data.assigned_by} has assigned a new task to process a voicemail message from ${$toNationalFormat(newValue.data.caller_id_number)}.`,
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    background: "#fcf1f1",
                    
                    confirmButtonText: 'Open Voicemails',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/voicemail');
                    }
                });
            }
            if (newValue.data.was_assigned_to == currentAgent.value && newValue.event == 'job_unassigned' && newValue.data.assigned_by!=`${user.value.firstname} ${user.value.lastname}`){
                $swal.fire({
                    position: 'top-end',
                    toast: true,
                    text: `${newValue.data.assigned_by} has unassigned a task to process a voicemail message from ${$toNationalFormat(newValue.data.caller_id_number)}.`,
                    // text: 'Click here to listen',
                    icon: 'warning',
                    color: "#716add",
                    background: "#f3fcf1",
                    
                    confirmButtonText: 'Open Voicemails',
                    showCancelButton: true,
                    cancelButtonText: 'Close'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigateTo('/company/voicemail');
                    }
                });
            }
        }


            
    },{deep:true});



</script>

