<template>
    <div
          ref="popupNotification"
          class="ms-2 toast position-fixed top-0 start-0 my-2"
          data-bs-delay="150000"
          style="z-index: 1080"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          >
          <div class="toast-body toast-dark d-flex ">
             <a @click="hideToast" type="button" aria-label="Close" class="top-right-button">
                <svg class="svg-icon" style="width: 1em; height: 1em; vertical-align: middle; fill: #ffffff; overflow: hidden;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                   <path d="M810.66 170.66c18.33 0 30.49 12.17 30.49 30.49 0 18-12.33 30.33-30.33 30.33L572.66 500.5l238.01 238.01c12.33 12.33 12.33 30.33 0 42.66-12.33 12.33-30.33 12.33-42.66 0L500 543.16 261.99 781.17c-12.33 12.33-30.33 12.33-42.66 0-12.33-12.33-12.33-30.33 0-42.66L457.34 500.5 219.33 262.5c-12.33-12.33-12.33-30.33 0-42.66s30.33-12.33 42.66 0L500 457.84 738.01 219.83c12.33-12.33 30.33-12.33 42.66 0z"/>
                </svg>
            </a>
            <div>
                <img src="/light-bulb.gif" style="max-height:60px" class="rounded-circle me-3" />
            </div>
             
            <div>
                
                <div v-if="customerIsFound">
                 
                    <div v-if="singleContact" class="row">
                    
                        <div class="col-8" style="min-width:330px"> 
                            <h4 class="text-white"> {{customer.companyName}} </h4>
                            <h5 v-if="customer.type=='individual'"  class="text-success"> Individual Customer </h5>

                            <h5 v-else class="text-success"> {{customer.firstName}} {{customer.lastName}} </h5>
                            <h6 class="text-success"> {{$toNationalFormat(phoneNumber)}} </h6>
                        </div>
                        <div class="col-4" style="min-width: 50px; display: flex; justify-content: flex-end;">
                            
                            <a href="javascript:;" class="btn btn-success" @click="openCustomerCard()">OPEN CLIENT CARD</a>
                            </div>
                    </div>

                   <div class="text-start" v-else>
                    <!-- {{customers}} -->
                      <h4 class="text-success">Multiple Contacts Found</h4>
                      <h5 class="text-success "> {{$toNationalFormat(phoneNumber)}} </h5>
                      <table  style="border-spacing: 0 3px; border-radius:13px; margin: -3px; width: 100%; border-collapse: separate;">
                         <tbody>
                            <tr v-for="item in customers" style=" border-radius: 10px; cursor:pointer" @click=openCustomerCard(item.account_id) >
                               <td style="min-width:240px; padding: 9px;background-color:#62607f; font-size:16px" >{{item.companyName}}</td>
                               <td style="min-width:240px; background-color:#62607f; font-size:16px" v-if="item.type=='individual'"> Individual Customer</td>
                               <td v-else style="min-width:240px; background-color:#62607f; font-size:16px">{{item.firstName}} {{item.lastName}}</td>
                            </tr>
                         </tbody>
                      </table>
                   </div>
                </div>
                <div v-else>
                   <div class="row">
                      <div class="col-9" style="min-width:300px">
                         <h4 class="text-white">Contact is not found</h4>
                         <h5 class="text-success"> {{$toNationalFormat(phoneNumber)}} </h5>
                      </div>
                      <div class="col-3 text-end" >
                         <a href="javascript:;" class="btn btn-success" @click="createContact(phoneNumber)">CREATE CONTACT</a>
                      </div>
                     </div>
                     <!-- <a href="javascript:;" class="btn btn-success" @click="openCustomerCard()">CREATE CONTACT</a>  -->
                    <!-- <h4 class="text-success">Contact is not found in </h4>
                   <a href="javascript:;" class="btn btn-success" @click="openCustomerCard()">CREATE CONTACT</a> -->
                   <!-- <h4 class="text-success">Multiple Contacts Found</h4> -->
                   <!-- My client card is ready for you to view. Please click the button below to view the client card. -->
                </div>
                <!-- My client card is ready for you to view. Please click the button below to view the client card. -->
            </div>
       
          </div>
       </div>
       <headerNewCRMContact/>
</template>

<script setup>
import { onMounted } from 'vue';

    const { $get, $swal } = useNuxtApp();

    const sipAccountCallState = useSipAccountCallState();
    const showPhoneWindow = useShowPhoneWindow(); 
    const contactNotification = useNewContactNotification();
    const popupNotification = ref(null);

    const phoneNumber = ref(""); 
    const customerIsFound = ref(false);
    const singleContact = ref(false);
    const callingAccoutId = ref("");
    const callcenterId = ref(""); 
    const customer = ref({});
    const customers = ref([]);


    // Reference for the Bootstrap Toast instance
    let popupNotificationInstance = null;
//   initToast();
    const initToast = async () => {
        // Ensure the DOM is rendered
        await nextTick();

        if (popupNotification.value) {
            popupNotificationInstance = new window.bootstrap.Toast(popupNotification.value);

            // Add an event listener to reset toast state when hidden
            popupNotification.value.addEventListener('hidden.bs.toast', () => {
            console.log("Toast hidden. Ready to show again.");
            });

            console.log("Toast instance initialized:", popupNotificationInstance);
        } else {
            console.error("Toast element not found!");
        }
    };
    const showToast = () => {
        if (popupNotificationInstance) {
            popupNotificationInstance.show();
        } else {
            console.error("Toast instance is not initialized.");
        }
    };
    const hideToast = () => {
        sipAccountCallState.value = {}
        if (popupNotificationInstance) {
            popupNotificationInstance.hide();
        } else {
            console.error("Toast instance is not initialized.");
        }
    };
    const openCustomerCard = (account_id) => {
        if (account_id == undefined){
            account_id = callingAccoutId.value;
        }
        showPhoneWindow.value = false;
        let link  = "/company/customers/"+account_id;
        navigateTo(link);
        hideToast();
    }

    const createContact = (number) => {
        // alert(number)
        contactNotification.value = {phone: number, callcenter_id: callcenterId.value};
        // let link  = `/crm/contacts`;
        // navigateTo(link);
        hideToast();
    }

    watch(() => sipAccountCallState.value, async (newValue) => {
        switch (newValue.state){
            case "RINGING":
                if (newValue.direction == 'inbound'){
                    phoneNumber.value = newValue.caller_id_number;
                    callcenterId.value = newValue.callcenter_id;
                    let contact = await $get("/api/protected/crm/contact/"+ encodeURIComponent(phoneNumber.value)+"?callcenter_id="+newValue.callcenter_id);
                    if (contact.data.length > 0){
                        customerIsFound.value = true;
                        if (contact.data.length == 1){
                            singleContact.value = true;
                            customer.value = contact.data[0];
                            callingAccoutId.value = contact.data[0].account_id;
                        } else {
                            singleContact.value = false;
                            customers.value = contact.data;
                        }
                
                    } else {
                        customerIsFound.value = false;
                    }
                    showToast();
                }
                break;
            case "EARLY":
                // console.log("RINGING");
                if (newValue.direction == 'outbound'){
                    phoneNumber.value = newValue.destination_number; // already with leading +
                    // callcenterId.value = newValue.callcenter_id;
                // } 
                
                    let contact = await $get("/api/protected/crm/contact/"+ encodeURIComponent(phoneNumber.value)+"?callcenter_id="+newValue.callcenter_id);
                    if (contact.data.length > 0){
                        customerIsFound.value = true;
                        if (contact.data.length == 1){
                            singleContact.value = true;
                            customer.value = contact.data[0];
                            callingAccoutId.value = contact.data[0].account_id;
                        } else {
                            singleContact.value = false;
                            customers.value = contact.data;
                        }
                    } else {
                        customerIsFound.value = false;
                    }
                    showToast();
                }

                break;
            case "ACTIVE":
                console.log("ACTIVE");
                break;
            case "HANGUP":
                console.log("HANGUP");
                hideToast();
                break;
            default:
                console.log("Default");
            break
        }

    }, {deep: true});

    onMounted(() => {
        initToast();
    });
   

</script>