<template>
    
    <client-only>
       <headerPopupCallNotification/>
       <!-- headerNewCRMContact -->
       <div>
          <div class="header-container">
             <header class="header navbar navbar-expand" style="margin-left:1px;margin-right:1px">
                <ul class="navbar-item theme-brand flex-row text-center mt-3 ">
                   <!-- <router-link to="/"> -->
                   <a href="javascript:void(0)" @click="moveToHomePage">
                   <img src="~/assets/images/logo.png" class="navbar-logo d-md-*" alt="logo" style="height:50px;margin-top:0px;" />
                   </a>
                   <!-- </router-link> -->
                </ul>

                
                <!-- <button type="button" class="btn btn-info" @click="emmulateCall">Click Callback</button> -->
                <!-- <ul class="navbar-item theme-brand flex-row text-center mt-3 d-block d-md-none ">
                   <router-link to="/">
                       <img src="~/assets/images/logo-mob.png" class="navbar-logo d-sm-block" alt="logo" style="height:50px;margin-top:0px;" />
                   </router-link>
                   </ul> -->
                   
                <div class="navbar-item flex-row ms-auto" >
                   <div class="dropdown nav-item notification-dropdown btn-group ">
                    <div class="me-3">
                        <!-- {{company.account_user_id}} -->
                        
                    </div>
                    
                    <div v-if="user.is_authorized" class="text-primary d-none d-md-block mt-2" style="font-size:13px;  text-transform: uppercase;">
                      <!-- {{company}}  -->
                      
                        <strong>{{ company.name }}</strong>
                        <!-- {{company.account_user_id}} -->
                      </div>
                      <headerNotifications v-if="company.name"/>  
                   </div>

                   
                   <div class="dropdown nav-item user-profile-dropdown notification-dropdown btn-group" style="z-index:1001">
                      <a v-if="user.is_authorized" href="javascript:;" id="ddluser" data-bs-toggle="dropdown"
                         aria-expanded="false">
                         <client-only>
                            
                            <template v-if="'avatar' in user">
                               <div class="avatar avatar-sm avatar-secondary mt-1 me-0">
                                  <img class="avatar-title rounded " :src="user.avatar" alt="avatar" />
                               </div>
                            </template>
                            <template v-else>
                               <div class="avatar avatar-sm avatar-secondary me-0" :class="color">
                                  <span class="avatar-title rounded-circle">{{ user.initials }}</span>
                               </div>
                            </template>
                         </client-only>
                      </a>
                      
                      <ul class="dropdown-menu dropdown-menu-right navbar-nav" aria-labelledby="ddluser">
                         <li role="presentation" class="text-center">
                            <client-only>
                               <router-link to="/profile" class="dropdown-item">
                                  <h5>{{ user.firstname }} {{ user.lastname }}</h5>
                                  <h6>{{ user.email }}</h6>
                               </router-link>
                            </client-only>
                         </li>
                         <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" />
                         <li role="presentation" class="text-end">
                            <div class="me-3 mt-2 mb-2">
                                <a class="font-family-credit" style="cursor:pointer;color:#0045c4" @click="navigateTo('/create')"> + Create a new client account</a>
                            </div>
                         </li>
                         <li role="presentation" class="text-end">
                            <button class="btn btn-sm mt-2 mb-2 me-3" @click="signOut()"> SIGN OUT </button>
                         </li>
                      </ul>
                      
                      <hr>
                      
                   </div>
                </div>
             </header>
          </div>
          <!-- {{company}} -->
       </div>
    </client-only>
    <!-- {{messages}} -->
    <!-- {{company}} -->
 </template>

<script setup>
// import { useUserWebSocket } from '~/composables/useUserWebSocket';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
const { $swal, $socket, $get, $parseJwt} = useNuxtApp();
const router = useRouter();
// const contactNotification = useNewContactNotification();

const { initWebSocket, unSubscribe, userMessages } = useWebSocket();
const currentAgent = useCurrentAgentId();
// const showPhoneWindow = useShowPhoneWindow(); 
const agentEvents = useAgentManagementMessages();


import './assets/sass/elements/avatar.scss';
import '~/assets/sass/widgets/widgets.scss';


const CRMContactsSettings = useCRMContactSettings();
const CRMAccountSettings = useCRMAccountSettings();
const CRMIssueTrackerSettings = useCRMIssueTrackerSettings();

// Initialize the Toast after the DOM is mounted
onMounted(async () => {
//   initToast();
  const crmSettings = await $get("/api/protected/crm/settings");
//   alert(JSON.stringify(crmSettings.data.contactSettings));
  CRMContactsSettings.value = crmSettings.data.contactSettings;
  CRMAccountSettings.value = crmSettings.data.accountSettings;
  CRMIssueTrackerSettings.value = crmSettings.data.issueTrackerSettings;
  
    const account_id = sessionStorage.getItem('account_id');
    if (account_id != null){
        const token = sessionStorage.getItem('token')
        const decodedToken = $parseJwt(token);
        for (let member of decodedToken.membership){
            if (member.account_id == account_id){
                if (member.agent_id){
                    currentAgent.value = member.agent_id;
                }
                company.value.name = member.name;
                company.value.country = member.country;
                company.value.role = member.role;
                company.value.mode = member.mode;
                company.value.account_user_id = member.account_user_id;
            }
        }

    
    }
});


const wsSubscriptionState = useWebsocketSubscriptionState();

const initializeUserWebSocket = () => {
    const token = sessionStorage.getItem('token');
    const account_id = sessionStorage.getItem('account_id');
    const account_user_id = sessionStorage.getItem('account_user_id');
    
    if (token && account_id && account_user_id) {
        initWebSocket(token, account_id, account_user_id);
    } else {
        console.log('Missing token, account_id, or account_user_id for user subscription');
    }
};

initializeUserWebSocket();
    
watch(() => agentEvents.value,  async (newValue) => {
    if (newValue != undefined){
        if (newValue.app == "sip-account-management" && newValue.event == "agent-updated") {
            if (newValue.data.status=="accepted" && newValue.data.last_status == "invited"){
                $swal.fire({
                    position: "top-end",
                    icon: "success",
                    text: `User ${newValue.data.firstname} ${newValue.data.lastname} has accepted the invitation to join.`,
                    timer: 10000,
                    showConfirmButton: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        router.push({ path: `/company` }).then(() => {      window.location.reload();    });
                    }
                });
            }
        }
    }
}, {deep:true, immediate:true});

watch(() => wsSubscriptionState.value,  async (newValue, oldValue) => {

        if (newValue == "unsubscribed"){
            unSubscribe();
        } else {
            initializeUserWebSocket();
        }
}, {deep:true, immediate:true});

watch(() => userMessages.value,  async (newValue) => {
    try {
        alert(JSON.stringify(newValue));
        let message = userMessages.value;
        
        switch (message.app){
            case "session-event":
                if (message.event == "agent-deleted") {
                    let {token} = await $get("/api/protected/refresh-key"); 
                    sessionStorage.setItem("token", token);
                    sessionStorage.removeItem("account_id");
                    sessionStorage.removeItem("account_user_id");
                    sessionStorage.removeItem("callcenter_id");
                    sessionStorage.removeItem("callcenter_name");
                    sessionStorage.removeItem("role");


                    $swal.fire({
                        position: "top-end",
                        icon: "warning",
                        title: "Your account has been deleted by the administrator.",
                        text: "Please contact your administrator for more information.",
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            navigateTo("/", { external: true});
                            // router.push({ path: `/` }).then(() => {      window.location.reload();    });
                        }
                    });
                }
       
            break;
        }
        
    } catch (error){
        console.log(error)
    }

});

const moveToHomePage = () => {
    const token = sessionStorage.getItem('token');
    const decodedToken = $parseJwt(token);
    
    if (decodedToken.membership.length == 1){
        router.push("/company");
    }
    if (decodedToken.membership.length > 1){
        sessionStorage.removeItem('account_id');
        company.value.name = "";
        router.push("/");
    }
}

const user = useUser();
user.value.is_authorized = true;
const dataIsChanged = useDataChanged();

// const modalTitle = ref("");
// let PaymentModal = ref(null);
// let phoneSelector = ref(null);
// let payment_type = ref("included_did");
// const nextButtonCaption = ref("NEXT: Payment details");
// const paymentParams = ref({});

const screen = ref("number_selection");


const addAdditinalPhoneNumber = () => {
    const screen = ref("number_selection");
    paymentParams.value = {paymentSettings:{subscription:5,service:"additional_did"}};
    modalTitle.value = "Purchase additional Phone Number";
    
   
    
    payment_type.value="additional_did";
    PaymentModal.value.openModal(); 
    phoneSelector.value.getRandomNumber();
    
}


// Function to subscribe to user events
// function subscribeUser() {
//   const token = sessionStorage.getItem('token'); // Ensure token is stored in sessionStorage
//   const account_id = sessionStorage.getItem('account_id');
//   const account_user_id = sessionStorage.getItem('account_user_id');

//   if (token && account_id && account_user_id) {
//     $socket.subscribeToUserEvents(token, account_id, account_user_id);
//   } else {
//     console.log('Missing token, account_id, or account_user_id for subscribing to user events');
//   }
// }

// function initializeSocket() {
//   $socket.init((error) => {
//     if (error) {
//       console.error('***************   Error initializing WebSocket:', error);
//     } else {
//       console.log(' ***************   WebSocket initialized successfully');
//       subscribeUser();
//       //   subscribeCallcenter()
//     }
//   });
// }
// subscribeUser();
// initializeSocket();

// if (process.client){
//     if ($socket) {
//         const { socket, init, subscribeToUserEvents } = $socket;
//         init((err) => {
//             if (err) {
//             console.error(err);
//             } else {
//             console.log('Initialization successful');
//             subscribeToUserEvents(sessionStorage.getItem('account_id'));
//             }
//         });
//     } else {
//         console.error('Socket plugin not found');
//     }
// }

const openStripePotal = async() => {
    let res = await $get("/api/protected/stripe/portal");
    // console.log(res.session.url);
    // navigateTo(res.session.url)
    window.location.href = res.session.url;
}

let color = ref("");

watch(() => user.value,  (newValue) => {
    if (!((newValue.firstname == undefined) && (newValue.lastname == undefined)) ) {
        user.value.initials = newValue.firstname[0]+newValue.lastname[0];
        user.value.has_avatar = false;
        color.value = getUserIdColor(newValue.email)
    }
    
}, { deep: true, immediate:true })

const company = useCompany();

let bc = useBreadcrumbs();
    // bc.value = [{ name: "Main page", is_current: true, url: "none" }];


function getUserIdColor(userId) {
    function hashCode(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    const colors = ['avatar-primary', 'avatar-secondary', 'avatar-danger']; // List of available colors
    const colorIndex = hashCode(userId) % colors.length; // Use hash code of user ID to get an index
    
    return colors[colorIndex];
}



const signOut = async () => {
    if(process.client) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('account_id')
        sessionStorage.removeItem('account_user_id')
        sessionStorage.removeItem('callcenter_id')
        sessionStorage.removeItem('callcenter_name')
        sessionStorage.removeItem('country')
        sessionStorage.removeItem('role')
    }
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;
            while ( i-- ) {
                // values.push( localStorage.getItem(keys[i]) );
                localStorage.removeItem(keys[i])
            }

            console.log(values);

    reloadNuxtApp({
        path: "/",
        ttl: 1000, // default 10000
    });
    // await navigateTo("/login")
}



</script>

<style>
.header-container {
    z-index: 1030;
    border-bottom: 1px solid #034ecc;
}

    #my_blue_nav li>a:hover {
        background-color: blue;
    }

    #my_blue_nav li.active a {
        background-color: blue !important;
    }

    #privacyWrapper .privacyContent {
        margin-top: 0px;
    }

    .navbar .theme-brand li.theme-logo img {

        height: 50px;
    }

    .navbar {
        background: #ffffff;
    }
    .toast {
        --bs-toast-zindex: 1090;
        --bs-toast-padding-x: 0.75rem;
        --bs-toast-padding-y: 0.5rem;
        --bs-toast-spacing: 1.5rem;
        --bs-toast-max-width: 650px;
    }
    .top-right-button {
    position: absolute; /* Allows positioning relative to the container */
    top: 10px; /* Distance from the top of the container */
    right: 10px; /* Distance from the right of the container */
  }
  .btn-close {
    --bs-btn-close-color: #ffffff;
  }
</style>

<style scoped>
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
    color: rgb(37, 97, 208);
    width: 22px;
    height: 22px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.server-log svg {
    color: #004d96;
    fill: rgb(0 63 150 / 37%);
}

</style>